import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { Loading, EmptyState } from "@antlerengineering/components";

import { requestSSO } from "../../firebase/callables";
export default function AuthLinkView() {
  const [error, setError] = useState();
  const handleRedirect = async (product: string, destination: string) => {
    const resp = await requestSSO(destination);
    console.log(resp);
    if (resp.data.error) setError(resp.data.error);
    else if (resp.data.sso) {
      const { authLinkId, authLinkKey } = resp.data.sso;
      window.location.replace(
        `https://${product}.antler.co/authLink?id=${authLinkId}&key=${authLinkKey}`
      );
    }
  };
  useEffect(() => {
    const { product, destination } = queryString.parse(window.location.search);
    handleRedirect(product as string, destination as string);
  }, []);
  if (error) return <EmptyState message={error} fullScreen />;
  return <Loading fullScreen message="Redirecting you" />;
}
