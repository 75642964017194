import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import {
  CssBaseline,
  MuiThemeProvider as ThemeProvider,
} from "@material-ui/core";
import Theme from "./theme";

import AuthView from "./pages/auth/SignInView";
import SSOOut from "./pages/auth/SSOOut";

import ForgotPasswordView from "./pages/auth/ForgotPasswordView";
import SignOutView from "./pages/SignOutView";

import GlobalStyles from "utils/GlobalStyles";
import {
  ErrorBoundary,
  Loading,
  EmptyState,
} from "@antlerengineering/components";
import NewForm from "pages/new";
import { AppProvider } from "./contexts/appContext";
import { SnackProvider } from "./contexts/snackContext";
import CustomBrowserRouter from "./utils/CustomBrowserRouter";
import PrivateRoute from "./utils/PrivateRoute";
import InviteView from "pages/auth/Invite";
import TransferInviteView from "pages/auth/TransferInvite";
import AuthLinkView from "pages/auth/AuthLinkView";

const Form = lazy(() => import("./pages/formWrapper"));
const Token = lazy(() => import("./pages/token"));
const TestForm = lazy(() => import("./pages/test"));

const App = () => {
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <GlobalStyles />
      <ErrorBoundary>
        <AppProvider>
          <SnackProvider>
            <CustomBrowserRouter>
              <Suspense fallback={<Loading fullScreen />}>
                <Switch>
                  <Route exact path="/submit" render={() => <Form />} />
                  <Route exact path="/token" render={() => <Token />} />
                  <Route exact path="/test" render={() => <TestForm />} />
                  <Route exact path="/new" render={() => <NewForm />} />
                  <Route exact path="/auth" render={() => <AuthView />} />
                  <Route exact path="/ssoOut" render={() => <SSOOut />} />
                  <Route
                    exact
                    path="/authLink"
                    render={() => <AuthLinkView />}
                  />
                  <Route exact path="/signout" render={() => <SignOutView />} />
                  <Route exact path="/invite" render={() => <InviteView />} />
                  <Route
                    exact
                    path="/transferInvite"
                    render={() => <TransferInviteView />}
                  />
                  <Route
                    exact
                    path="/forgotPassword"
                    render={() => <ForgotPasswordView />}
                  />
                  <PrivateRoute
                    exact
                    path="/protectedNew"
                    render={() => <NewForm />}
                  />
                  <PrivateRoute
                    exact
                    path="/protectedSubmit"
                    render={() => <Form />}
                  />
                  <Route
                    render={() => (
                      <EmptyState message="Page Not Found" fullScreen />
                    )}
                  />
                </Switch>
              </Suspense>
            </CustomBrowserRouter>
          </SnackProvider>
        </AppProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
