import { generateTheme } from "@antlerengineering/components";

const Theme = generateTheme({
  props: {
    MuiContainer: {
      maxWidth: "sm",
    },
  },
});

export default Theme;
