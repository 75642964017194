import React, { useEffect } from "react";
import { Loading } from "@antlerengineering/components";
import * as queryString from "query-string";
import { db } from "../firebase";
const NewForm = () => {
  const createSubmission = (parentData: any) => async (query: any) => {
    const doc = await db.collection(decodeURIComponent(query.path)).add({
      ...parentData,
      parent: parentData ?? {},
      isComplete: false,
      createdAt: new Date(),
    });

    window.location.replace(
      `/submit?path=${encodeURIComponent(doc.path)}&form=${query.form}`
    );
  };
  useEffect(() => {
    //if (authContext.currentUser) {
    // const uid = authContext.currentUser.uid;

    const query: any = queryString.parse(window.location.search);
    if (query.path) {
      const parentDocPath = query.path.match(".*/")[0];

      db.doc(parentDocPath)
        .get()
        .then(snapshot => {
          createSubmission(snapshot.data())(query);
        });
    }

    // }
  }, [window.location.search]);

  return <Loading message="Generating new form!" fullScreen />;
};
export default NewForm;
