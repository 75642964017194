import React from "react";

import {
  makeStyles,
  createStyles,
  Typography,
  Card,
  Button,
  Grid,
  LinearProgress,
} from "@material-ui/core";

import logo from "@antlerengineering/components/src/assets/antler-logo.svg";
const useStyles = makeStyles(theme =>
  createStyles({
    card: {
      maxWidth: 419,
      minWidth: 300,
      width: "100%",
      // height: 360,
      margin: "auto",
      marginTop: 50,
    },
    logo: { width: 89, height: 35, margin: "20px auto" },
    grid: {
      height: "100%",
      padding: theme.spacing(4),
    },
    support: { margin: "auto", maxWidth: 400 },
  })
);

export default function AuthCard({
  children,
  height = 300,
  loading = false,
  back,
  hideLogo = false,
  footer = "HAVING PROBLEMS AUTHENTICATING?",
}: {
  children: any;
  height: number;
  loading?: boolean;
  back?: string;
  hideLogo?: boolean;
  footer?: string;
}) {
  const classes = useStyles();
  return (
    <Grid
      className={classes.card}
      container
      direction="column"
      alignItems="flex-start"
    >
      {back && (
        <Button color="inherit" onClick={() => window.location.assign(back)}>
          ‹ BACK
        </Button>
      )}
      <Card style={{ height, width: "100%" }}>
        {loading && <LinearProgress />}
        <Grid
          container
          direction="column"
          className={classes.grid}
          //spacing={1}
          justify="space-evenly"
        >
          {!hideLogo && (
            <img src={logo} alt="Antler" className={classes.logo} />
          )}

          {children}
        </Grid>
      </Card>
      <Grid
        className={classes.support}
        container
        direction="row"
        alignItems="center"
        justify="center"
      >
        {/* <Typography variant="overline">{footer}</Typography>
        <Button
          color="primary"
          onClick={() => {
            window.location.href = `mailto:engineering@antler.co?subject=unable%20to%20authenticate%20an%20account`;
          }}
        >
          EMAIL US ›
        </Button> */}
      </Grid>
    </Grid>
  );
}
