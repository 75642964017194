import React, { useState, useEffect } from "react";

import * as queryString from "query-string";
import {
  makeStyles,
  createStyles,
  Grid,
  Button,
  Typography,
  Card,
} from "@material-ui/core";
import { getInviteData, validateNewAccount } from "../../firebase/callables";
import { googleProvider, auth } from "../../firebase";
import GoogleLogo from "assets/google-icon.svg";
import TextField from "@material-ui/core/TextField";
import { Loading, EmptyState } from "@antlerengineering/components";
import { useSnackContext } from "contexts/snackContext";
import AuthCard from "./AuthCard";

const useStyles = makeStyles(theme =>
  createStyles({
    card: {
      maxWidth: 420,
      height: 570,
      margin: "auto",
      marginTop: theme.spacing(6),
    },
    gLogo: { margin: theme.spacing(1) },
    support: { margin: "auto", maxWidth: 400 },
    logo: {
      margin: "auto",
      display: "block",
    },

    root: {
      padding: theme.spacing(4),
      height: "100%",
    },
  })
);

enum InviteStatuses {
  expired = "EXPIRED",
  loading = "LOADING",
  completed = "COMPLETED",
  active = "ACTIVE",
  invalid = "INVALID",
  valid = "VALID",
}

enum AuthTypes {
  password = "password",
  google = "google",
}

export default function SignUpView() {
  const snack = useSnackContext();

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [inviteStatus, setInviteStatus] = useState<InviteStatuses>(
    InviteStatuses.loading
  );

  const [displayName, setDisplayName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [inviteData, setInviteData] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
  });

  useEffect(() => {
    setDisplayName(`${inviteData.firstName} ${inviteData.lastName}`);
  }, [inviteData]);
  const [authType, setAuthType] = useState<AuthTypes | undefined>();

  const invite = queryString.parse(window.location.search);
  const { id, key } = invite;
  const getInvite = async () => {
    if (id && key) {
      const response = await getInviteData({
        id: id as string,
        key: key as string,
      });
      validateNewAccount({
        id: id as string,
        key: key as string,
        displayName,
      });
      setInviteStatus(response.data.status);
      if (response.data.success) {
        if (response.data.redirect) {
          window.location.replace(`/${response.data.redirect}`);
        }
        setInviteData(response.data.invite);
        setAuthType(response.data.authType);
      } else {
        console.log(response);
      }
    }
  };

  const validateAccount = async (user: firebase.User | null) => {
    if (user === null) {
      return snack.open({
        message: "Error: unauthenticated",
        severity: "error",
      });
    }

    const response = await validateNewAccount({
      id: invite.id as string,
      key: invite.key as string,
      displayName,
    });

    if (response.data.success) {
      await auth.currentUser?.getIdToken(true);
      window.location.replace(response.data.redirectURL);
      snack.open({ message: response.data.message, severity: "success" });
    } else {
      setLoading(false);
      snack.open({ message: response.data.message, severity: "error" });
    }
  };
  useEffect(() => {
    // get invite data on initial load
    getInvite();
  }, []);
  const handleEmailSignup = async () => {
    if (password !== confirmPassword) {
      return snack.open({
        message: "Passwords don't match",
        severity: "error",
      });
    } else if (password.length < 8) {
      return snack.open({
        message: "Password needs to be at least 8 characters long",
        severity: "error",
      });
    }
    setLoading(true);
    try {
      const authCred = await auth.createUserWithEmailAndPassword(
        inviteData.email,
        password
      );
      await validateAccount(authCred.user);
    } catch (error) {
      setLoading(false);
      snack.open({ message: error.message, severity: "error" });
      console.log(error);
    }
  };

  const handleGmailSignup = async () => {
    setLoading(true);
    try {
      const authCred = await auth.signInWithPopup(googleProvider);
      await validateAccount(authCred.user);
    } catch (error) {
      setLoading(false);
      snack.open({ message: error.message, severity: "error" });
      console.log(error);
    }
  };

  if (loading) return <Loading fullScreen message="Creating your account" />;
  switch (inviteStatus) {
    case InviteStatuses.loading:
      return <Loading fullScreen message="Loading onboarding form" />;

    case InviteStatuses.invalid:
      return (
        <EmptyState
          fullScreen
          message="Invalid invite link"
          description="Please insure that you're using the latest invite link sent to your email, as we can't seem find your invite"
        />
      );
    case InviteStatuses.completed:
      return (
        <EmptyState
          fullScreen
          message="Already Signed up!"
          description="Looks like you have already completed your onboarding"
        />
      );

    case InviteStatuses.expired:
      return (
        <EmptyState
          fullScreen
          message="This invite link has expired"
          description="Looks like you have already completed your onboarding"
        />
      );
    default:
      break;
  }

  return (
    <>
      {authType === AuthTypes.google ? (
        <AuthCard height={420} footer={"Having problems signing up?"}>
          <Typography variant="body1">
            Welcome onboard!
            <br />
            Please confirm your name and sign in using the following account:
            <br />
            <b>{inviteData.email}</b>
          </Typography>

          <Button
            onClick={() => {
              window.location.assign(`/transferInvite?id=${id}&key=${key}`);
            }}
          >
            REGISTER USING ANOTHER EMAIL ›
          </Button>
          <TextField
            key="displayName"
            label={"Your Name"}
            type="text"
            id="displayName"
            fullWidth
            value={displayName}
            onChange={e => {
              setDisplayName(e.target.value);
            }}
            variant="filled"
          />
          <Button
            onClick={handleGmailSignup}
            color="primary"
            size="large"
            variant="outlined"
          >
            <img src={GoogleLogo} width={16} className={classes.gLogo} />
            {` `} SIGN IN WITH GOOGLE
          </Button>
        </AuthCard>
      ) : (
        <AuthCard height={570} footer={"Having problems signing up?"}>
          <Typography variant="body1">
            Welcome onboard!
            <br />
            Please confirm your name and create a password to set up your Antler
            account.
            <br />
            <b>{inviteData.email}</b>
          </Typography>
          <TextField
            key="displayNamePassword"
            label={"Your Name"}
            type="text"
            id="displayNamePassword"
            fullWidth
            value={displayName}
            onChange={e => {
              setDisplayName(e.target.value);
            }}
            variant="filled"
          />
          <TextField
            name="password"
            label={"Password"}
            type="password"
            id="password"
            variant="filled"
            value={password}
            onChange={e => {
              setPassword(e.target.value);
            }}
            placeholder={"Min. 8 characters"}
            fullWidth
          />
          <TextField
            label={"Confirm Password"}
            type="password"
            name="confirmPassword"
            placeholder={"Min. 8 characters"}
            value={confirmPassword}
            onChange={e => {
              setConfirmPassword(e.target.value);
            }}
            variant="filled"
            fullWidth
          />
          <Button
            onClick={handleEmailSignup}
            placeholder={"Min. 8 characters"}
            color="primary"
            size="large"
            variant="contained"
            fullWidth
          >
            Sign up
          </Button>
        </AuthCard>
      )}
    </>
  );
}
