import React, { useContext, useEffect, useState } from "react";
import { auth } from "../firebase";
import { SnackbarOrigin } from "@material-ui/core/Snackbar";
import Snack from "components/Snack";

interface ISnack {
  message: string;
  duration?: undefined | number;
  action?: undefined | JSX.Element;
  severity?: "success" | "info" | "warning" | "error" | undefined;
  position?: SnackbarOrigin | undefined;
}

const EMPTY_STATE: ISnack = {
  message: "",
  duration: undefined,
  action: undefined,
  severity: undefined,
  position: undefined,
};
const DEFAULT_STATE = {
  ...EMPTY_STATE,
  isOpen: false,
  close: () => {},
  open: (props: ISnack) => {},
};
// Create our Context
export const SnackContext = React.createContext(DEFAULT_STATE);

export const useSnackContext = () => useContext(SnackContext);

interface ISnackProviderProps {
  children: React.ReactNode;
}

export const SnackProvider: React.FC<ISnackProviderProps> = ({ children }) => {
  const [state, setState] = useState({ ...EMPTY_STATE, isOpen: false });
  const close = () => {
    setState({ ...EMPTY_STATE, isOpen: false });
  };
  const open = (props: ISnack) => {
    setState({ ...props, isOpen: true });
  };
  return (
    <SnackContext.Provider
      value={{
        ...state,
        close,
        open,
      }}
    >
      {children}

      <Snack />
    </SnackContext.Provider>
  );
};
