import { functions } from "./index";
//import { FireTableFilter } from "../hooks/useFiretable";

export enum CLOUD_FUNCTIONS {
  getJWTWithUID = "getJWTWithUID",
  getJWTWithIdToken = "getJWTWithIdToken",
  getTestJWT = "getTestJWT",
  formStatus = "FF_FormStatus",
  getInviteData = "callable-InviteData",
  validatedNewAccount = "callable-ValidateNewAccount",
  requestPasswordReset = "RequestPasswordReset",
  getAuthLinkJWT = "callable-GetAuthLinkJWT",
  transferInvite = "transferInvite",
  RequestSSO = "callable-RequestSSO",
}

export const cloudFunction = (
  name: string,
  input: any,
  success: Function,
  fail: Function
) => {
  const callable = functions.httpsCallable(name);
  callable(input)
    .then(result => {
      if (success) {
        success(result);
      }
    })
    .catch(error => {
      if (fail) {
        fail(error);
      }
    });
};

export const getJWTWithIdToken = (data: { idToken: string }) =>
  functions.httpsCallable(CLOUD_FUNCTIONS.getJWTWithIdToken)(data);
export const getTestJWT = () =>
  functions.httpsCallable(CLOUD_FUNCTIONS.getTestJWT)();

export const getFormStatus = (data: { path: string }) =>
  functions.httpsCallable(CLOUD_FUNCTIONS.formStatus)(data);

export const getInviteData = (data: { id: string; key: string }) =>
  functions.httpsCallable(CLOUD_FUNCTIONS.getInviteData)(data);

export const transferInvite = (data: {
  id: string;
  key: string;
  newEmail: string;
}) => functions.httpsCallable(CLOUD_FUNCTIONS.transferInvite)(data);
export const validateNewAccount = (data: {
  id: string;
  key: string;
  displayName: string;
}) => functions.httpsCallable(CLOUD_FUNCTIONS.validatedNewAccount)(data);

export const requestPasswordReset = (email: String) =>
  functions.httpsCallable(CLOUD_FUNCTIONS.requestPasswordReset)({ email });
export const getAuthLinkJWT = (id: string, key: string) =>
  functions.httpsCallable(CLOUD_FUNCTIONS.getAuthLinkJWT)({ id, key });

export const getJWTWithUID = (uid: string) =>
  functions.httpsCallable(CLOUD_FUNCTIONS.getJWTWithUID)({
    uid,
  });

export const requestSSO = (targetPath: string) =>
  functions.httpsCallable(CLOUD_FUNCTIONS.RequestSSO)({ targetPath });
