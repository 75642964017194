import React, { useState } from "react";
import * as queryString from "query-string";

import { Typography, TextField, Button } from "@material-ui/core";
import AuthCard from "./AuthCard";
import { useSnackContext } from "contexts/snackContext";
import { transferInvite } from "../../firebase/callables";

export default function SignInView() {
  const invite = queryString.parse(window.location.search);
  const { id, key } = invite;
  const snack = useSnackContext();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const back = `/invite?id=${id}&key=${key}`;
  return (
    <AuthCard height={320} loading={loading} back={back} hideLogo>
      <Typography variant="overline">Change email address</Typography>

      <Typography variant="body1">
        Please type the email address you would like to use when using Antler
        Hub.
        <br />
      </Typography>
      <TextField
        label={"Email Address"}
        name={"email"}
        type="email"
        value={email}
        onChange={e => {
          setEmail(e.target.value);
        }}
      />

      <Button
        fullWidth
        variant="outlined"
        color="primary"
        size="large"
        onClick={async () => {
          setLoading(true);
          const resp = await transferInvite({
            id: id as string,
            key: key as string,
            newEmail: email,
          });
          if (resp.data.success) {
            window.location.replace(`/invite?id=${id}&key=${key}`);
          } else {
            snack.open({ message: resp.data.message, severity: "error" });
          }
        }}
      >
        Change Email
      </Button>
    </AuthCard>
  );
}
