import React from "react";

import { makeStyles, createStyles } from "@material-ui/core";

export const useGlobalStyles = makeStyles(theme =>
  createStyles({
    "@global": {
      // Overrides <CSSBaseline />

      // layout styles
      main: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),

        [theme.breakpoints.down("xs")]: {
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),
        },
      },
      header: {
        height: 48,
        marginBottom: theme.spacing(2),
      },

      a: {
        fontWeight: "bold",
        color: theme.palette.primary.main,
      },
    },
  })
);

const GlobalStyles: React.FunctionComponent = () => {
  useGlobalStyles();
  return null;
};

export default GlobalStyles;
