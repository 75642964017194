import React, { useContext } from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";

import { AppContext } from "../contexts/appContext";
import { Loading } from "@antlerengineering/components";

interface IPrivateRouteProps extends RouteProps {
  render: NonNullable<RouteProps["render"]>;
}

const PrivateRoute: React.FC<IPrivateRouteProps> = ({ render, ...rest }) => {
  console.log(rest);
  const { currentUser } = useContext(AppContext);

  if (!!currentUser) return <Route {...rest} render={render} />;

  if (currentUser === null) {
    const redirectURL = window.location.href.split("/").pop();
    return <Redirect to={`/auth?redirect=${redirectURL}`} />;
  }

  return (
    <Route
      {...rest}
      render={() => <Loading message="Authenticating" fullScreen />}
    />
  );
};

export default PrivateRoute;
